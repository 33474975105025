// Variables
@import 'variables';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;500;600&display=swap');

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';

@import 'sweetalert2/src/sweetalert2.scss';
@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import "../css/plugins/common.css";
@import "../css/plugins/reset.css";

@import "mixins";
@import "layout";
@import "home";
@import "us";
@import "services";
@import "projects";
@import "contact";
@import "privacy-notice";

// Body
$brand-primary: #084FA0;
$brand-secondary: #ECECEC;
$body-bg: #f8fafc;
$gradinet-f: #147aaa;
$gradinet-s: #fff;
$gradinet-t: #fff;
$brand-text-p: #fff;
$brand-text-s: #000;
$brand-color-s: #ee652c;
$brand-color-t: #ee652c;
$project-header: #0A3269;
$project-label: #002163;

/** Sizes */
$baseFontSize: 16px;
$bp-1k: 120em;
$bp-2k: 160em;

/** Media Query */
$mq-2k: "(min-width: #{$bp-2k})";

// Typography
$font-family-roboto: 'Roboto', sans-serif;
$font-family-monserrat: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

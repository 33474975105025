.us {

    .ichepOurTeam {

        .wrapUs {
            background-color: #0A3269;
            color: white;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                padding-top: 82px;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 97px;
            }

            .wrapText {

                @include media-breakpoint-up(md) {
                    padding-bottom: 163px;
                    padding-top: 93px;
                }

                h1 {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 34px;
                    line-height: 40px;
                    display: block;
                    padding-top: 170px;
                    margin: 0 auto 15px;
                    width: 80%;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        font-size: 64px;
                        line-height: 80px;
                        padding-top: 70px;
                        width: 85%;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-top: 92px;
                        width: 283px;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 auto 80px;
                    width: 80%;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        width: 85%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 283px;
                    }
                }

            }

            .wrapImg {
                picture {
                    @include media-breakpoint-up(md) {
                        opacity: 0;
                    }
                }

                @include media-breakpoint-up(md) {
                    background-image: url("/images/us/ichep-general-contractor.webp");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                }
            }
        }
        //.wrapUs


    }
    //.ichepOurTeam

    .ichepOurProjects {
        background-image: url("/images/us/arquitectos-obras-ichep-edificios-back.webp");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 230% auto;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            background-size: 170% auto;
        }
        @include media-breakpoint-up(lg) {
            background-position: 0 69%;
            background-size: 100% auto;
        }

        .wrapTeam {
            position: relative;

            .overhead {
                background-color: white;
                height: 60px;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -28px;
                width: 85%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    left: auto;
                    height: 95px;
                    top: -52px;
                    width: 66.666%;
                }
                @include media-breakpoint-up(lg) {
                    top: -75px;
                }
            }

            .wrapText {

                @include media-breakpoint-up(md) {
                    order: 1;
                }

                h2 {
                    color: #002163;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 34px;
                    line-height: 40px;
                    margin: 0 auto 15px;
                    width: 75%;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        font-size: 64px;
                        line-height: 80px;
                        width: 90%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 548px;
                    }
                }

                p {
                    color: black;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 auto;
                    width: 80%;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        width: 90%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 548px;
                    }

                    label {
                        color: #084FA0;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        transition: all 0.5s ease-out;
                    }
                }
            }

            .wrapImg {

                picture {
                    @include media-breakpoint-up(md) {
                        opacity: 0;
                    }
                }

                @include media-breakpoint-up(md) {
                    background-image: url("/images/us/ichep-nuestro-equipo.webp");
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    background-position: center top;
                    order: 0;
                }

            }

            .wrapBtn {
                @include media-breakpoint-up(lg) {
                    margin: 0 auto;
                    width: 548px;
                }
                .btn {
                    background-color: transparent;
                    border: 1px solid #084FA0;
                    color: #084FA0;
                    display: block;
                    height: 43px;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 85px auto 60px;
                    width: 235px;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        margin-left: 0;
                    }
                }
            }
        }
        //.wrapTeam

        .wrapOurTrajectory {
            padding-bottom: 120px;

            h2 {
                color: #002163;
                font-style: normal;
                font-weight: 300;
                font-size: 34px;
                line-height: 40px;
                margin: 50px auto 10px;
                width: 80%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 64px;
                    line-height: 80px;
                    margin-left: 5%;
                    width: 455px;
                }
                @include media-breakpoint-up(lg) {
                    padding-left: 25px;
                    padding-right: 50%;
                    margin-top: 106px;
                    width: 818px;
                }
                @include media-breakpoint-up(xl) {
                    padding-left: 0;
                }
            }

            label {
                color: #084FA0;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                margin: 0 auto 50px;
                display: block;
                width: 80%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    //margin-left: 5%;
                    width: 455px;
                }
                @include media-breakpoint-up(lg) {
                    width: 818px;
                    //padding-left: 28px;
                    //padding-right: 50%;
                }
                @include media-breakpoint-up(xl) {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
        //.wrapOurTrajectory

        .timeline {
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                max-width: 860px;
            }

            .item {
                position: relative;
                transition: all 0.5s ease-out;

                .timeline-detail {
                    background: #084FA0;
                    height: 37px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 114px;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        right: 18px;
                        bottom: 30px;
                    }

                    &:before {

                        @include media-breakpoint-up(lg) {
                            background-size: 100% auto;
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            background-image: url("/images/us/vector.webp");
                            height: 13px;
                            position: absolute;
                            content: "";
                            right: 0;
                            bottom: -13px;
                            width: 24px;
                        }

                    }

                    label {
                        text-align: center;
                        display: block;
                        height: 100%;
                        line-height: 38px;
                        width: 100%;
                        color: white;
                        margin: 0;
                        transition: all 0.5s ease-out;
                    }
                }
                //.timeline-detail
            }
            //.ite,

            .slick-prev {
                background-image: url('/images/us/arrow-blue.webp');
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 18px auto;
                left: 3px;
                display: block;
                height: 33px;
                transform: rotate(180deg);
                bottom: -79px;
                top: auto;
                width: 25px;
                z-index: 100;
                transition: all 0.5s ease-out;

                &:before {
                    font-family: $font-family-monserrat;
                    opacity: 0;
                }

                @include media-breakpoint-up(lg) {
                    left: 6px;
                    bottom: -73px;
                }
            }
            .slick-next {
                background-image: url(/images/us/arrow-blue.webp);
                background-position: 7px 0;
                background-repeat: no-repeat;
                background-size: 18px auto;
                right: 3px;
                display: block;
                height: 33px;
                bottom: -92px;
                top: auto;
                width: 25px;
                z-index: 100;
                transition: all 0.5s ease-out;

                &:before {
                    font-family: $font-family-monserrat;
                    opacity: 0;
                }

                @include media-breakpoint-up(lg) {
                    right: 15px;
                    bottom: -85px;
                }
            }
        }
        //.timeline

        .wrapTimeline {
            position: relative;
            margin-top: 40px;
            height: 60px;
            width: 100%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                margin: 30px auto 0;
                max-width: 790px;
            }

            .timeline-years {
                position: relative;
                transition: all 0.5s ease-out;

                .item {
                    position: relative;
                    height: 55px;
                    transition: all 0.5s ease-out;

                    &:before {
                        background-color: #D9D9D9;
                        content: "";
                        height: 3px;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        transition: all 0.5s ease-out;
                    }

                    &:after {
                        background-color: #002163;
                        content: "";
                        height: 3px;
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        transition: all 0.5s ease-out;
                    }

                    &.active {
                        label {
                            color: #002163;
                            font-weight: 600;
                        }

                        &:after {
                            width: 100%;
                        }
                    }

                    label {
                        color: #084FA0;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 40px;
                        margin: 0;
                        display: block;
                        text-align: center;
                        width: 100%;
                        transition: all 0.5s ease-out;

                    }
                }


            }
        }
        //.wrapTimeline

        .wrap-timeline-description {
            font-weight: 200;
            margin: 40px auto 0;
            max-width: 800px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.8);
            padding-top: 30px;

            p {
                margin: 0 auto;
                font-size: 18px;
                width: 90%;
            }
        }

    }
    //.ichepOurProjects

    .ichepOurPropose {

        .wrapOurProposeVision {
            background-image: url("/images/us/obras-construccion-comercial-industrial-residencial.webp");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            padding-bottom: 60px;
            padding-top: 60px;
            transition: all 0.5s ease-out;

            h2 {
                color: white;
                font-style: normal;
                font-weight: 300;
                font-size: 34px;
                line-height: 40px;
                margin: 50px auto 10px;
                width: 80%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 58px;
                    line-height: 60px;
                    width: 465px;
                }

                &.vision {
                    @include media-breakpoint-up(lg) {
                        margin-left: 0;
                    }
                }
            }

            p {
                color: white;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                margin: 0 auto 50px;
                display: block;
                width: 80%;
                transition: all 0.5s ease-out;

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    transition: all 0.5s ease-out;
                }

                @include media-breakpoint-up(md) {
                    width: 472px
                }

                &.vision {
                    @include media-breakpoint-up(lg) {
                        margin-left: 0;
                    }
                }
            }

            h3 {
                color: white;
                font-style: normal;
                font-weight: 300;
                font-size: 28px;
                line-height: 40px;
                margin: 50px auto 10px;
                text-align: center;
                width: 250px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 38px;
                    line-height: 60px;
                    width: 570px;
                }
                @include media-breakpoint-up(lg) {
                    text-align: left;
                    width: 100%;
                }
            }

            .wrapBtn {
                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 175px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 35px;
                    margin-left: 25px;
                    width: 512px;
                }

                .btn {
                    background-color: transparent;
                    border: 1px solid white;
                    width: 235px;
                    height: 43px;
                    font-size: 14px;
                    text-align: center;
                    display: block;
                    margin: 35px auto 0;

                    @include media-breakpoint-up(lg) {
                        margin: 0;
                    }

                    svg {
                        display: inline-block;
                        margin-left: 10px;
                        width: 16px;
                    }
                }
            }

        }
        //.wrapOurProposeVision
    }
}

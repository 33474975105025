.privacy-notice {

    .bannerBox {
        padding-top: 94px;

        .item {
            .container {
                .BannerCaption {
                    h1 {
                        font-size: 2.125em;

                        @include media-breakpoint-up(lg) {
                            font-size: 54px;
                        }
                    }
                }
            }
        }
    }

    .content {
        font-weight: 300;
        padding-bottom: 30px;
        padding-top: 30px;

        @include media-breakpoint-up(md) {
            padding-bottom: 60px;
            padding-top: 60px;
        }
    }

}

.contact {

    .col-1 {
        background-color: #013774;
        background-image: url("/images/contact/contact-back.webp");
        background-repeat: no-repeat;
        background-position: 0 center;
        background-size: 150% auto;
        min-height: 400px;
        padding-top: 156px;

        @include media-breakpoint-up(xl) {
            background-size: 120% auto;
            padding-top: 190px;
        }
        @media (min-width: 1400px) {
            padding-bottom: 150px;
            padding-top: 120px;
        }
        @media (min-width: 1700px) {
            padding-bottom: 300px;
            padding-top: 140px;
        }

        .block-title {
            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 80%;
            }
            @include media-breakpoint-up(xl) {
                margin-left: 35%;
                width: 52%;
            }

            h1 {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 34px;
                text-align: center;
                color: #FFFFFF;

                @include media-breakpoint-up(md) {
                    font-size: 48px;
                    text-align: left;
                    margin: 0 auto 15px auto;
                    width: 100%;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 58px;
                }
            }
            label {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin: 0 auto 40px auto;
                width: 185px;
                display: block;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    font-size: 18px;
                    margin-bottom: 10px;
                    width: 100%;
                }
                @include media-breakpoint-up(xl) {
                    text-align: left;
                    font-size: 26px;
                }
            }
            p {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                margin: 0 auto 60px auto;
                color: #FFFFFF;
                display: block;
                width: 230px;

                @include media-breakpoint-up(md) {
                    text-align: left;
                    font-size: 18px;
                    margin-bottom: 10px;
                    line-height: 24px;
                    width: 500px;
                }
                @include media-breakpoint-up(lg) {
                    width: 100%;
                }

                span {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
        //.block-title

        .block-form {
            margin: 0 auto 160px auto;
            width: 90%;

            @include media-breakpoint-up(md) {
                width: 500px;
            }
            @include media-breakpoint-up(lg) {
                width: 80%;
            }
            @include media-breakpoint-up(xl) {
                margin-left: 35%;
                margin-bottom: 40px;
                width: 52%;
            }

            form {
                width: 100%;

                .form-group {
                    margin: 0 auto 30px auto;

                    label {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        color: #FFFFFF;
                        display: block;
                    }
                    input {
                        background-color: transparent;
                        border: 1px solid #FFFFFF;
                        color: white;
                        height: 44px;
                        padding-left: 15px;
                        padding-right: 15px;
                        width: 100%;

                        &[type=submit] {
                            text-align: center;
                            text-transform: uppercase;
                            width: 215px;
                        }
                    }
                    textarea {
                        background-color: transparent;
                        border: 1px solid #FFFFFF;
                        height: 120px;
                        color: white;
                        padding: 15px;
                        width: 100%;
                    }
                }
            }

            .error-fields {
                display: block;
                margin-bottom: 15px;
                color: red;
                text-align: left;
            }

        }
        //.block-form

        .visit-us {
            font-family: $font-family-roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            margin: 0 auto 100px auto;
            color: #FFFFFF;
            display: block;
            width: 300px;

            @include media-breakpoint-up(md) {
                text-align: left;
                font-size: 30px;
                line-height: 36px;
                width: 500px;
            }
            @include media-breakpoint-up(lg) {
                margin: 0 auto 100px;
                width: 80%;
            }
            @include media-breakpoint-up(xl) {
                margin-left: 35%;
                width: 52%;
            }
        }
    }
    //.col-1

    .col-2 {

        @include media-breakpoint-up(xl) {
            padding-top: 96px;
        }

        .block-blue {
            background-color: #013774;
            left: 0;
            height: 96px;
            top: 0;
            position: absolute;
            width: 100%;
        }

        .row-1 {

            .wrap-construccion-laboratorio {

                @include media-breakpoint-up(xl) {
                    background-size: auto 100%;
                    background-position: center top;
                    background-repeat: no-repeat;
                    height: 885px;
                }

                img {
                    width: 100%;

                    @include media-breakpoint-up(xl) {
                        opacity: 0;
                    }
                }
            }
        }
        .row-2 {
            display: flex;
            img {
                width: 50%;
            }
        }
    }
    //.col-2

    .col-map {
        @media (min-width: 1400px) {
            margin-bottom: 160px;
        }
        .map {
            background-image: url("/images/contact/mapa.webp");
            background-size: cover;
            background-position: 35% top;
            height: 600px;
            position: relative;
            width: 100%;

            @include media-breakpoint-up(md) {
                background-position: 0 0;
            }

            @media (min-width: 1400px) {
                top: -150px;
                margin: 0 auto;
                height: 766px;
                width: 1100px;
            }
            @media (min-width: 1700px) {
                width: 1400px;
            }

            .marker {
                background-image: url("/images/contact/pin-ichep.webp");
                background-size: 100% auto;
                background-repeat: no-repeat;
                position: absolute;
                height: 80px;
                left: 42%;
                top: 63%;
                transform: translate(-50%, -63%);
                -ms-transform: translate(-50%, -63%);
                width: 245px;
            }
        }
    }
    //.col-map

    .bannerBox {
        &.thank-you {

            .item {
                .container {
                    h1 {
                        padding-top: 65px;
                    }
                }
            }
        }
    }
    //.bannerBox
}

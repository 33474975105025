.services {

    .bannerBox {
        padding-top: 94px;
    }

    #servicesTabs {
        background-color: $brand-secondary;
        width: 100%;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        li {
            display: flex;
            list-style: none;
            align-items: center;
            height: 100px;
            position: relative;
            width: 25%;

            @include media-breakpoint-up(lg) {
                height: 63px;
            }

            &.current {
                background-color: $brand-primary;
                color: white;
                font-weight: 600;

                a {
                    color: white;
                }
            }

            &.bar-left {
                &:before {
                    background-color: $brand-primary;
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 70%;
                    width: 1px;
                    transition: all 0.5s;
                }
            }
            &.bar-right {
                &:after {
                    background-color: $brand-primary;
                    content: '';
                    position: absolute;
                    right: -1px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 70%;
                    width: 1px;
                    transition: all 0.5s;
                }
            }

            &.current {
                &.bar-left {
                    &:before {
                        left: 1px;
                    }
                }
                &.bar-right {
                    &:after {
                        right: 1px;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                width: 325px;
            }

            a {
                background-color: transparent;
                border: 0;
                border-radius: 0;
                color: #002163;
                display: block;
                height: auto;
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                padding-top: 7px;
                width: 100%;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 23px;
                    padding-top: 10px;
                }

                /*
                &#generalConstructorID, &#projectArqID, #bimID {
                    padding-top: 15px;

                    @include media-breakpoint-up(md) {
                        padding-top: 20px;
                    }
                }
                 */
            }
        }
    }
    //#servicesTabs

    .tab-content {
        padding-top: 65px;

        @include media-breakpoint-up(xl) {
            padding-top: 90px;
        }

        .block-title {
            margin: 0 auto 95px auto;
            text-align: center;
            width: 290px;

            @include media-breakpoint-up(md) {
                width: 600px;
            }
            @include media-breakpoint-up(xl) {
                width: 740px;
            }

            h2 {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 32px;
                margin-bottom: 10px;
                color: #002163;

                @include media-breakpoint-up(xl) {
                    font-size: 58px;
                    margin-bottom: 20px;
                }
            }
            p {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #123986;

                @include media-breakpoint-up(xl) {
                    font-size: 20px;
                    line-height: 28px;
                }

                strong {
                    font-weight: 600;
                }
            }
        }
        //.block-title

        .img-full {
            @include media-breakpoint-up(xl) {
                margin-bottom: 6rem !important;
            }
        }
        //.img-full

        .group {
            position: relative;
            transition: all 0.5s;

            &.wCol {
                margin: 0 auto;
                width: 80%;

            }

            @include media-breakpoint-up(md) {
                display: flex;
            }

            @include media-breakpoint-up(xl) {
                margin: 0 auto;
                width: 900px;
            }

            .backElement {
                background-position: 0 0;
                background-size: cover;
                position: absolute;
                width: 100%;
                height: 628px;
                z-index: -1;

                @include media-breakpoint-up(lg) {
                    height: 900px;
                }

                &.ing-ichep {
                    background-image: url("/images/services/ingenieria-ichep.webp");
                    top: 25%
                }
                &.obra-ichep {
                    background-image: url("/images/services/arquitectos-obras-ichep-edificios.webp");
                    top: 15%
                }
            }

            .block-img {
                display: flex;
                justify-content: left;

                picture {
                    width: 93%;

                    @include media-breakpoint-up(md) {
                        width: 85%;
                    }
                }

                &.right {
                    display: flex;
                    justify-content: end;
                }
            }
            //.black-img

            .block-text {
                margin: 60px auto 60px auto;
                width: 290px;

                @media (min-width: 1400px) {
                    width: 330px;
                }

                @include media-breakpoint-up(md) {
                    margin: 10px auto 60px auto;
                }

                &.big {
                    @include media-breakpoint-up(lg) {
                        width: 450px;
                    }
                }

                &.wmr {
                    padding-left: 20px;
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        //margin-right: 5%;
                        padding-left: 0;
                    }

                    img {
                        max-width: 362px;
                        width: 100%;
                    }
                }
                &.wml {
                    padding-left: 20px;
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        //margin-left: 7%;
                        padding-left: 0;
                    }

                    img {
                        max-width: 362px;
                        width: 100%;
                    }
                }

                h3 {
                    font-family: $font-family-roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    color: #084FA0;
                }

                ul {
                    padding-left: 2px;

                    li {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        width: 100%;

                        span {
                            display: block;
                            font-weight: 200;
                            padding-left: 15px;

                            &:before {
                                content: "•";
                                color: #2283F4;
                                font-weight: bold;
                                display: inline-block;
                                width: 16px;
                                margin-left: -1rem;
                                font-size: 20px;
                            }
                        }

                        &::marker {
                            color: #084FA0;
                        }
                    }
                }

                &.detail-ing {
                    ul {
                        li{
                            font-weight: 500;
                            margin-bottom: 30px;

                            span {
                                display: block;
                                font-weight: 200;
                                padding-left: 15px;

                                &:before {
                                    content: "•";
                                    color: #2283F4;
                                    font-weight: bold;
                                    display: inline-block;
                                    width: 16px;
                                    margin-left: -1rem;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
                //&.detail-ing

                &.cross-ing {
                    label {
                        display: inline-block;
                        //width: 170px;

                        @media (min-width: 1400px) {
                            width: 100%;
                        }

                        &.no-affected {
                            display: block;
                            margin-bottom: 20px;
                            font-weight: 300;
                            font-size: 16px;
                            width: auto;
                        }
                    }
                }
                //&.cross-ing

                &.mechanic {
                    ul {
                        columns: 2;
                        -webkit-columns: 2;
                        -moz-columns: 2;
                    }

                    @include media-breakpoint-up(md) {
                        order: 2;
                    }

                    label {
                        display: inline-block;
                        //width: 170px;

                        @media (min-width: 1400px) {
                            width: 100%;
                        }

                        &.no-affected {
                            display: block;
                            margin-bottom: 20px;
                            font-weight: 300;
                            font-size: 16px;
                            width: auto;
                        }
                    }
                }
                //&.mechanic

                &.civil {
                    @include media-breakpoint-up(md) {
                        order: 2;
                    }
                }
                //&.civil

                &.hvac {
                    @include media-breakpoint-up(md) {
                        width: 85%;
                    }
                }
                //&.hvac

                &.clean-room {
                    @include media-breakpoint-up(md) {
                        order: 2;
                    }
                }
                //&.clean-room
            }
            //.block-text

            label {
                &.disclaimer {
                    color: $brand-primary;
                    display: block;
                    font-weight: 300;
                    font-size: 14px;
                    margin: 15px auto;
                    //width: 90%;

                    @media (min-width: 1400px) {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }

        }
        //.group

    }

    #generalConstructor {
        background-image: url("/images/services/arquitectos-obras-ichep-edificios.webp");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 0;

        @include media-breakpoint-up(xl) {
            background-image: url("/images/services/arquitectos-obras-ichep-edificios-lg.webp");
        }
    }

    #projectArq {
        background-image: url("/images/services/arquitectos-obras-ichep-edificios.webp");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 0;

        @include media-breakpoint-up(xl) {
            background-image: url("/images/services/arquitectos-obras-ichep-edificios-lg.webp");
        }

        .backElement {

            @include media-breakpoint-up(lg) {
                height: 500px;
            }
        }
    }

    #ingDetail, #bim {
        background-image: url("/images/services/arquitectos-obras-ichep-edificios.webp");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 0;

        @include media-breakpoint-up(xl) {
            background-image: url("/images/services/arquitectos-obras-ichep-edificios-lg.webp");
        }

        .backElement {

            @include media-breakpoint-up(lg) {
                background-size: contain;
            }
        }
        .block-text {
            label {
                font-weight: 300;
            }
        }
    }

    .backElement {
        background-position: 0 0;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 628px;
        z-index: -1;

        &.ing-ichep {
            background-image: url("/images/services/ingenieria-ichep.webp");
            background-size: auto 100%;
            height: 700px;
            top: 240%;
            transform: translate(0%, -50%);
            -ms-transform: translate(0, -50%);
        }
        &.obra-ichep {
            background-image: url("/images/services/arquitectos-obras-ichep-edificios.webp");
            background-size: auto 100%;
            height: 700px;
            top: 490%;
            transform: translate(0%, -50%);
            -ms-transform: translate(0, -50%);
        }
    }
}

body {
    background-color: white;
    background-repeat: no-repeat;
    min-height: 100vh;
    font-size: $font-size-base;
    font-family: $font-family-roboto;
    overflow-x: hidden;

    &.noScroll{
        main{
            height: 100vh;
            overflow: hidden;
        }
        .hamburger{
            span{
                &:first-child{
                    transform: rotate(45deg) translate(7px, 7px);
                }
                &:nth-child(2){
                    opacity: 0;
                    transform: translateY(-100%);
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
        .ichep-mainMenu,
        .ichep-mainMenu ul{
            opacity: 1;
            visibility: visible;
        }
    }

    header {
        background: rgba(255, 255, 255, 0.95);
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        position: fixed;
        font-family: $font-family-monserrat;
        z-index: 10;
        width: 100%;
        filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.10));
        -webkit-transition: all 0.5s; /* Safari */
        transition: all 0.5s;

        .ichep-logo {
            max-width: 149px;
            z-index: 7;
            position: relative;
        }

        .ichep-mainMenu{
            -webkit-transition: all 0.5s; /* Safari */
            transition: all 0.5s;

            @include media-breakpoint-down(md){
                padding: 0;
                position: fixed;
                top: 0;
                right: 0;
                bottom:0;
                left: 0;
                z-index: 6;
                opacity: 0;
                visibility: hidden;
            }
            ul{
                @include media-breakpoint-down(md){
                    background-color: white;
                    padding: 24vw 10.6666%;
                    height: auto;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 250ms ease, visibility 250ms ease;
                }
                list-style-type: none;
                margin: 0;
                padding: 0;
                @include media-breakpoint-up(lg){
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                li{
                    background-repeat: no-repeat;
                    background-position: 89% center;
                    display: flex;
                    min-height: 50px;
                    padding-top: 11px;
                    border-bottom: 1px solid black;
                    padding-bottom: 7px;
                    justify-content: center;
                    -webkit-transition: all 0.5s; /* Safari */
                    transition: all 0.5s;
                    @include media-breakpoint-up(lg){
                        justify-content: unset;
                        border: none;
                    }



                    &.lang {
                        min-width: 72px;

                        a {
                            span {
                                color: gray;
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 700;

                                &.active {
                                    color: #002163;
                                }
                            }
                        }
                    }

                    &.social-media {

                        .facebook {
                            svg {
                                width: 20px;

                                path {
                                    fill: #002163;
                                }
                            }
                        }
                        .instagram {
                            margin-left: 20px;
                            svg {
                                width: 25px;

                                path {
                                    fill: #002163;
                                }
                            }
                        }

                    }


                    a{
                        color: black;
                        display: inline-block;
                        position: relative;
                        -webkit-transition: all 0.5s; /* Safari */
                        transition: all 0.5s;

                        @include media-breakpoint-up(xl){
                            border:none;
                            font-size: 16px;
                            padding: 0;

                            &::after{
                                background: rgba($gradinet-f,1);
                                content: "";
                                height: 2px;
                                width: 100%;
                                position: absolute;
                                left:0;
                                bottom: 0;
                                transform-origin: 100% 50%;
                                transform: scale3d(0, 1, 1);
                                transition: transform 0.3s;
                            }

                            &:hover::after,
                            &.current::after{
                                transform-origin: 0% 50%;
                                transform: scale3d(1, 1, 1);
                            }
                        }
                    }

                    &.active {
                        a {
                            color: #05002C;
                            font-weight: 700;
                        }
                    }

                }
            }
        }
        &.stick{
            background: $brand-color-s;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
        }
        .menuMb{
            padding: 0;
            position: relative;
            z-index: 7;
            .hamburger{
                background-color: transparent;
                border-radius: 20px;
                width: 65px;
                padding-top: 8px;
                padding-left: 15px;
                height: 40px;
                padding-right: 15px;
                @include media-breakpoint-up(md){
                    text-align: right;
                }
                span{
                    background: $brand-text-s;
                    border-radius: 2px;
                    height: 3px;
                    width: 100%;
                    display: block;
                    margin-bottom: 7px;
                    transition: opacity 250ms ease, transform 250ms ease;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }//header

    main {
        overflow-x: hidden;
    }

    footer {
        background: #F6F6F6;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        left: 0;
        position: relative;
        bottom: 0;
        width: 100%;
        -webkit-transition: all 0.5s; /* Safari */
        transition: all 0.5s;

        @include media-breakpoint-up(lg) {
            padding-bottom: 68px;
            padding-top: 68px;
        }

        .wrap-img {
            -webkit-transition: all 0.5s; /* Safari */
            transition: all 0.5s;
            position: relative;
            &:after {
                background-color: #002163;
                content: "";
                position: absolute;
                height: 1px;
                left: 0;
                right: 0;
                margin: 0 auto;
                max-width: 450px;
                width: 80%;

                @include media-breakpoint-up(md) {
                    height: 100%;
                    max-height: 206px;
                    left: auto;
                    right: 0;
                    top: 0;
                    width: 1px;
                }
            }
            img {
                display: block;
                margin: 30px auto 30px;
                max-width: 170px;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    margin-right: 80px;
                }
            }
        }

        .wrap-address-info {
            max-width: 360px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            -webkit-transition: all 0.5s; /* Safari */
            transition: all 0.5s;

            @include media-breakpoint-up(md) {
                margin-top: 10px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 0;
                margin-left: 95px;
            }

            strong {
                font-weight: 500;
                color: #002163;
                display: block;

                @include media-breakpoint-up(md) {
                    margin-top: 20px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
            }
            label {
                color: black;
                display: inline-block;
                font-weight: 300;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 15px;
                }
            }

            .phone-social {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    margin: 0;
                }

                .social {
                    margin-right: 20%;
                    display: flex;

                    a {
                        margin-left: 20px;

                        svg {
                            path {
                                fill: #002163;
                            }
                        }
                    }
                }
            }
        }

        .wrap-rights {
            margin-top: 20px;
            -webkit-transition: all 0.5s; /* Safari */
            transition: all 0.5s;

            @include media-breakpoint-up(md) {
                margin-left: auto;
                margin-right: auto;
                max-width: 450px;
                margin-top: 40px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 17px;
                margin-left: 95px;
                max-width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            @include media-breakpoint-up(xl) {
                max-width: 585px;
            }

            strong {
                color: #002163;
                display: block;
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }
                @include media-breakpoint-up(lg) {
                    display: inline-block;
                }
            }
            label {
                color: #002163;
                display: block;
                text-align: center;
            }
            a {
                margin-top: 10px;
                text-align: center;
                color: black;
                text-decoration: underline;
                display: block;
                @include media-breakpoint-up(md) {
                    text-align: left;
                }
                @include media-breakpoint-up(lg) {
                    margin-right: 20px;
                    display: inline-block;
                }
            }
        }


    }//footer
}

a {
    &:link,&:focus,&:visited,&:hover{
        font-style: normal;
        text-decoration: none;
    }
}
.btn {
    font-size: inherit;
    line-height: inherit;
}

.btn {
    border: 1px solid white;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 10px 20px 10px 20px;
    color: white;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

    &.main-text {
        display: inline-block;
        height: 100%;
        width: 62%;
        border-radius: 0;
        z-index: 1;

        &.animate {
            color: $brand-color-s;
            animation: zoom-in-zoom-out 1.5s ease-out infinite;

        }
    }

}

.wrap-buttons {
    &.small {
        .btn {
            height: 35px;
            width: 35px;
        }
    }
}

.play-video {
    position: absolute;
    left: 0;
    top: 0;
    height: 400px;
    width: 200px;
    //z-index: 0;
}

.main-text {
    z-index: 2;

    &.animate {
        color: $brand-color-s;
        animation: zoom-in-zoom-out 1.5s ease-out infinite;
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.video-play, video {
    border-radius: 20px;
}

.visit-guide {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;

    img {
        display: block;
        margin: 0 auto;
        max-width: 720px;
        width: 100%;
    }

    .close-guide {
        position: absolute;
        color: white;
        width: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        bottom: 130px;

        &.menu {
            bottom: auto;
            top: 21px;
            background-color: transparent;
            height: 50px;
            width: 50px;
            right: 43px;
            left: auto;

            &:after {
                background-color: transparent;
            }
        }

        &:after {
            left: 0;
            background-color: white;
            content: "";
            position: absolute;
            height: 3px;
            width: 100%;
            bottom: -2px;
        }
    }
}

.align-self-center {
    margin: 0 auto;
}

.bannerBox {
    .item {
        position: relative;

        .container {
            position: absolute;
            z-index: 1;
            top: 34%;
            padding: 0;
            line-height: normal;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(sm) {
                top: 42vh;
                left: 0;
                right: 0;
            }
            @include media-breakpoint-up(md) {
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                bottom: unset;
            }

            .BannerCaption {
                color: white;
                position: relative;
                text-align: center;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                > h1 {
                    display: block;
                    font-family: $font-family-roboto;
                    font-size: em(34px);
                    font-weight: 300;
                    line-height: 0.88;
                    margin-bottom: 10px;
                    text-align: center;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        min-height: 90px;
                        margin-bottom: 0;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: em(50px);
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: em(64px);
                    }

                    @media #{$mq-2k} {
                        font-size: em(70px);
                    }

                    span {
                        margin: 10px auto;
                        display: block;
                        font-size: 16px;
                        text-align: center;
                        line-height: 20px;
                        width: 280px;

                        @include media-breakpoint-up(md) {
                            width: 310px;
                        }
                    }
                }

            }
        }
    }

}

//.HomeBannerBox

.home {

    .HomeBannerBox {
        .item {
            position: relative;

            video {
                border-radius: 0;
                width: 100%;
            }

            .container {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                line-height: normal;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(sm) {
                    top: 42vh;
                    left: 0;
                    right: 0;
                }
                @include media-breakpoint-up(md) {
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    bottom: unset;
                }

                .BannerCaption {
                    color: white;
                    position: relative;
                    text-align: center;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }

                    > span {
                        display: block;
                        font-size: 40px;
                        font-weight: 100;
                        line-height: 40px;
                        margin: 0 auto;
                        text-align: center;
                        width: 90%;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            min-height: 90px;
                            margin-bottom: 0;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 50px;
                            line-height: 50px;
                            width: 520px;
                        }

                        @include media-breakpoint-up(xl) {
                            font-size: 70px;
                            line-height: 70px;
                            width: 720px;
                        }

                        @media #{$mq-2k} {
                            font-size: 75px;
                            line-height: 75px;
                        }
                    }

                    p {
                        text-align: center;
                        font-size: em(18px);
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(lg) {
                            font-size: em(20px);
                            margin: 20px 0;
                        }
                    }

                    button {
                        display: block;
                        margin: 0 auto;
                        border: 0;
                        background-color: transparent;
                        height: 50px;
                        width: 65px;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            height: 50px;
                            width: 60px;
                        }

                        svg {
                            height: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }

    }

    //.HomeBannerBox

    .ichepServices {


        .head {
            background-image: url("/images/home/construccion.webp");
            background-repeat: no-repeat;
            background-size: 200% auto;
            background-position: top center;
            padding-top: 60px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                background-size: 140% auto;
                background-position: bottom center;
            }
            @include media-breakpoint-up(lg) {
                background-size: cover;
            }


            h1 {
                font-style: normal;
                font-weight: 300;
                font-size: 32px;
                line-height: 34px;
                text-align: center;
                color: white;
                width: 300px;
                margin: 0 auto;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 48px;
                    line-height: 50px;
                    width: 585px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 58px;
                    line-height: 60px;
                    width: 710px;
                }

                span {
                    font-weight: 500;
                    transition: all 0.5s ease-out;
                }
            }

            .slogan {
                color: white;
                display: block;
                margin: 10px auto 70px;
                font-weight: 600;
                font-size: 18px;
                text-align: center;
                line-height: 24px;
                width: 195px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 22px;
                    margin-bottom: 184px;
                    margin-top: 30px;
                    width: 100%;
                }
            }

            .wrapSecTitle {
                width: 100%;

                @include media-breakpoint-up(md) {
                    position: relative;
                }

                .whiteSpace {
                    background-color: white;
                }

                .wrapImg {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    background-color: white;

                    @include media-breakpoint-up(md) {
                        background-color: transparent;
                        justify-content: left;
                        order: 2;
                    }

                    img {
                        @include media-breakpoint-up(md) {
                            max-width: 323px;
                            width: 323px;
                        }
                    }
                }

                .wrapSubtitle {
                    @include media-breakpoint-up(md) {
                        order: 1;
                    }

                    .subtitle {
                        background-color: white;
                        color: #002163;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 32px;
                        line-height: 60px;
                        display: block;
                        padding-bottom: 52px;
                        padding-top: 52px;
                        text-align: center;
                        margin-bottom: 0;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            padding-top: 59px;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size: 58px;
                            line-height: 60px;
                            padding-top: 115px;
                            padding-bottom: 110px;
                        }
                        @include media-breakpoint-up(xl) {
                            text-align: right;
                            padding-right: 20%;
                        }
                    }
                }
            }
        }

        .wrapOur {
            background-color: white;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                background-image: url("/images/home/edificios.webp");
                background-size: 600px auto;
                background-position: right center;
                background-repeat: no-repeat;
                margin-bottom: 60px;
            }

            .itemService {
                position: relative;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    height: 340px;
                }
                @include media-breakpoint-up(xl) {
                    height: 420px;
                }

                .main {

                    @include media-breakpoint-up(md) {
                        height: 100%;
                        margin: 0 auto;
                        width: 85%;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 870px;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 1080px;
                    }

                    picture {

                        &.right {
                            @include media-breakpoint-up(md) {
                                display: flex;
                                justify-content: end;
                            }
                            @include media-breakpoint-up(lg) {
                                justify-content: center;
                            }

                            img {
                                @include media-breakpoint-up(xl) {
                                    margin-left: 175px;
                                }
                            }
                        }

                        img {
                            display: block;
                            margin: 0 auto;
                            transition: all 0.5s ease-out;

                            @include media-breakpoint-up(md) {
                                max-width: 505px;
                                margin: 0;
                                width: 390px;
                            }
                            @include media-breakpoint-up(xl) {
                                width: 505px;
                            }
                        }
                    }

                    .wrapInfo {
                        background-color: white;
                        padding: 20px 32px 20px 32px;
                        margin: 0 auto;
                        max-width: 320px;
                        position: relative;
                        top: -35px;
                        width: 85%;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(sm) {
                            width: 390px;
                            max-width: 420px;
                        }

                        &.general-constructor {
                            @include media-breakpoint-up(lg) {
                                top: -190px;
                                right: -105px;
                            }
                        }

                        &.arq-design {
                            @include media-breakpoint-up(lg) {
                                top: -170px;
                                right: -274px;
                                width: 338px;
                            }
                            @include media-breakpoint-up(xl) {
                                right: -400px;
                            }
                        }

                        &.ing-detail {
                            @include media-breakpoint-up(lg) {
                                top: -190px;
                                right: -105px;
                            }
                        }

                        &.building-info {
                            @include media-breakpoint-up(lg) {
                                top: -170px;
                                right: -274px;
                                width: 338px;
                            }
                            @include media-breakpoint-up(xl) {
                                right: -400px;
                            }
                        }

                        h2 {
                            color: #084FA0;
                            display: block;
                            font-weight: 500;
                            font-size: 24px;
                            transition: all 0.5s ease-out;
                        }

                        p {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 20px;
                            width: 100%;
                            transition: all 0.5s ease-out;
                        }
                    }
                }

            }

            .slick-dots {
                bottom: 0;

                @include media-breakpoint-up(xl) {
                    bottom: 15%;
                }

                li {
                    button {
                        &:before {
                            font-size: 15px;
                        }
                    }
                }
            }

        }

    }

    //.ichepServices

    .ichepOurConstructionSpecialties {
        background-image: url("/images/home/build-back.webp");
        background-repeat: no-repeat;
        background-size: 250% auto;
        background-position: 0 bottom;
        padding-bottom: 60px;
        padding-top: 60px;

        @include media-breakpoint-up(md) {
            background-size: 100% auto;
        }

        .head {

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 900px;
            }
            @include media-breakpoint-up(xl) {
                width: 1080px;
            }

            h2 {
                font-style: normal;
                font-weight: 300;
                font-size: 32px;
                line-height: 34px;
                text-align: left;
                color: #002163;
                width: 300px;
                margin: 0 auto;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    font-size: 27px;
                    width: 100%;

                    br {
                        display: none;
                    }
                }
                @include media-breakpoint-up(lg) {
                    font-size: 42px;
                    line-height: 42px;
                    width: 290px;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 58px;
                    line-height: 58px;
                    width: 435px;
                }
            }

            p {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                margin: 20px auto 40px;
                width: 285px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                    width: 100%;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 15px;
                    margin: 0 auto 20px;
                }
            }

            .btn {
                border-color: #084FA0;
                color: #084FA0;
                display: block;
                height: 45px;
                margin: 0 auto;
                width: 285px;

                @include media-breakpoint-up(md) {
                    margin-left: 0;
                    width: 215px;
                }
                @include media-breakpoint-up(lg) {
                    height: 43px;
                    line-height: 22px;
                }
            }

        }

        .wrapConstructions {
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 900px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: 30px;
                width: 1080px;
            }

            .commercialsProjects {
                background-image: url("/images/home/Construccion-proyectos-comerciales.webp");
                background-repeat: no-repeat;
                background-size: 310px auto;
                background-position: center center;
                padding-top: 160px;
                margin-bottom: 30px;
                //margin-top: 60px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                }
                @include media-breakpoint-up(xl) {
                    background-size: 100% auto;
                    margin-bottom: 0;
                    padding-bottom: 50px;
                }

                h2 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                    display: block;
                    color: #FFFFFF;
                    margin: 0 auto;
                    width: 210px;

                    @include media-breakpoint-up(md) {
                        padding-top: 120px;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 285px;

                    @include media-breakpoint-up(md) {
                        padding-top: 0;
                        width: 90%;
                    }
                }
            }

            //.commercialsProjects

            .residentialProjects {
                background-image: url("/images/home/proyectos-residenciales-ichep.webp");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                padding-top: 200px;
                padding-bottom: 30px;
                margin-bottom: 30px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    background-size: 120% auto;
                    background-position: 15px center;
                    padding-top: 100px;
                    margin-top: 50px;
                    height: 372px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 50px;
                    background-size: 100% auto;
                }

                h2 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                    color: #FFFFFF;

                    @include media-breakpoint-up(md) {
                        padding-top: 65px;
                        width: 100%;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 330px;

                    @include media-breakpoint-up(md) {
                        width: 90%;
                    }
                }
            }

            //.residentialProjects

            .industrialProjects {
                background-image: url("/images/home/Proyectos-industriales-construccion-xs.webp");
                background-repeat: no-repeat;
                background-size: 310px 100%;
                background-position: center center;
                padding-top: 160px;
                margin-bottom: 30px;
                margin-top: 30px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    background-image: url("/images/home/Proyectos-industriales-construccion.webp");
                    background-size: 100% auto;
                    margin-top: 21px;
                    padding-top: 95px;
                    height: 335px;
                }
                @include media-breakpoint-up(xl) {
                    background-size: 100% auto;
                    margin-top: 35px;
                    padding-top: 95px;
                    height: 335px;
                }

                h2 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                    display: block;
                    color: #FFFFFF;
                    margin: 0 auto;
                    width: 210px;

                    @include media-breakpoint-up(md) {
                        padding-top: 65px;
                        width: 100%;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 285px;

                    @include media-breakpoint-up(md) {
                        width: 90%;
                    }
                }
            }

            //.industrialProjects

            .cleanRooms {
                background-image: url("/images/home/cuartos-limpios.webp");
                background-repeat: no-repeat;
                background-size: 117% auto;
                background-position: center center;
                padding-top: 160px;
                margin-bottom: 30px;
                margin-top: 50px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    background-size: 150% auto;
                    background-position: 15px center;
                }
                @include media-breakpoint-up(lg) {
                    background-size: 101% auto;
                }
                @include media-breakpoint-up(xl) {
                    //margin-top: 10px;
                }

                h2 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                    color: #FFFFFF;

                    @include media-breakpoint-up(md) {
                        width: 100%;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 330px;

                    @include media-breakpoint-up(md) {
                        width: 90%;
                    }
                }
            }

            //.cleanRooms

            .institutionalBuildings {
                background-image: url("/images/home/Edificios-institucionales.webp");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                padding-top: 160px;
                margin-bottom: 60px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    background-size: 115% auto;
                    padding-top: 130px;
                }
                @include media-breakpoint-up(lg) {
                    background-size: 100% auto;
                    padding-top: 100px;
                    padding-bottom: 35px;
                }

                h2 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 36px;
                    text-align: center;
                    display: block;
                    margin: 0 auto;
                    color: #FFFFFF;

                    @include media-breakpoint-up(md) {
                        width: 100%;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 330px;

                    @include media-breakpoint-up(md) {
                        width: 550px;
                    }
                }
            }

            //.institutionalBuildings

        }

        //.wrapConstructions

        .wrapArchitecturalProject {
            padding-top: 60px;

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 900px;
            }
            @include media-breakpoint-up(xl) {
                width: 1080px;
            }


            .head {
                width: 100%;

                h2 {
                    color: white;
                    font-style: normal;
                    font-weight: 100;
                    font-size: 32px;
                    line-height: 34px;
                    text-align: center;
                    width: 300px;
                    margin: 15px auto 0;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        font-size: 27px;
                        width: 100%;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 42px;
                        line-height: 42px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 58px;
                        line-height: 58px;
                    }
                }

                p {
                    color: white;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 20px auto 40px;
                    text-align: center;
                    width: 285px;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                        width: 90%;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 15px;
                        margin: 0 auto 20px;
                        width: 710px;
                    }
                }

            }

            .wrapTime {
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    color: white;
                    font-style: normal;
                    font-weight: 100;
                    font-size: 16px;
                    display: block;
                    text-transform: uppercase;
                    width: 50%;

                    &.left {
                        padding-left: 15px;
                        text-align: left;
                    }
                    &.right {
                        padding-right: 15px;
                        text-align: right;
                    }
                }
            }

            .wrapImageDrag {
                background-color: #0A346D;
                width: 100%;
                height: 175px;

                @include media-breakpoint-up(md) {
                    height: 360px;
                }
                @include media-breakpoint-up(lg) {
                    height: 493px;
                }
            }

            .btn {
                border-color: #fff;
                color: #fff;
                display: block;
                height: 45px;
                margin: 55px auto 0;
                width: 285px;

                @include media-breakpoint-up(md) {
                    font-size: 15px;
                    width: 215px;
                }
                @include media-breakpoint-up(lg) {
                    height: 43px;
                    line-height: 22px;
                }
            }

        }

        //.wrapArchitecturalProject

    }

    //.ichepOurConstructionSpecialties

    .ichepContact {
        background-color: #013774;
        padding-bottom: 70px;
        padding-top: 60px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            padding-top: 0;
        }

        .head {

            @include media-breakpoint-up(lg) {
                margin-top: 85px;
            }

            h2 {
                font-style: normal;
                font-weight: 100;
                font-size: 32px;
                line-height: 34px;
                text-align: center;
                color: white;
                width: 100%;
                display: block;
                margin: 0 auto 30px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    display: block;
                    font-size: 38px;
                    line-height: 40px;
                    width: 100%;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 42px;
                    line-height: 42px;
                }
                @include media-breakpoint-up(xl) {
                    font-size: 58px;
                    line-height: 58px;
                }
            }

            p {
                color: white;
                display: block;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                width: 70%;
                margin: 0 auto 60px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                    width: 80%;
                }
                @include media-breakpoint-up(xl) {
                    width: 505px;
                }
            }
        }

        .wrap-img {
            background-image: url("/images/home/contact/head-xs.webp");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            padding-top: 80%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                background-image: url("/images/home/contact/head.webp");
            }

            @include media-breakpoint-up(xl) {
                padding-top: 50%;
            }
        }

        .wrapForm {
            padding-top: 60px;
            margin: 0 auto;
            width: 90%;

            @include media-breakpoint-up(lg) {
                padding-top: 0;
                width: 83%;
            }
            @include media-breakpoint-up(xl) {
                width: 505px;
            }

            .form-group {
                margin-bottom: 35px;

                label {
                    color: white;
                    display: block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 15px;
                }

                input {
                    color: white;
                    background-color: transparent;
                    border: 1px solid white;
                    padding-left: 20px;
                    padding-right: 20px;
                    height: 44px;
                    width: 100%;
                }
                textarea {
                    color: white;
                    background-color: transparent;
                    border: 1px solid white;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 15px;
                    height: 106px;
                    width: 100%;
                }

                input[type=submit] {
                    background: transparent;
                    border: 1px solid white;
                    color: white;
                    width: 235px;
                    height: 43.57px;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        //.wrapForm

        .google-maps {
            background-image: url('/images/home/contact/marker.webp');
            background-size: 35px auto;
            background-position: left center;
            background-repeat: no-repeat;
            display: block;
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 45px;
            height: 46px;
            color: #FFFFFF;
            width: 145px;
            margin: 30px auto 0;

            @include media-breakpoint-up(lg) {
                margin: 30px auto 60px;
            }
        }
    }

    //.ichepContact

}

.ichepOurClients {
    background-color: white;
    padding-bottom: 60px;
    padding-top: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
    }

    .head {
        padding-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            width: 900px;
        }
        @include media-breakpoint-up(xl) {
            width: 1080px;
        }

        h2 {
            font-style: normal;
            font-weight: 100;
            font-size: 32px;
            line-height: 34px;
            text-align: center;
            color: #002163;
            width: 300px;
            margin: 0 auto;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                font-size: 38px;
                line-height: 40px;
                width: 100%;
            }
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                line-height: 42px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 58px;
                line-height: 58px;
            }
        }

    }

    .wrapOur {
        padding: 20px;
        box-sizing: border-box;
        margin: 0 auto 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media-breakpoint-up(xl) {
            width: 1024px;
        }

        .itemClient {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin: 0 auto;
                width: 80%;

                @include media-breakpoint-up(md) {
                    width: auto;
                }
            }
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        font-size: 15px;
                    }
                }
            }
        }
    }

}

//.ichepOurClients

.ichepTestimonials {
    background-color: white;
    padding-bottom: 95px;

    .head {
        padding-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            width: 900px;
        }
        @include media-breakpoint-up(xl) {
            width: 1080px;
        }

        h2 {
            font-style: normal;
            font-weight: 100;
            font-size: 32px;
            line-height: 34px;
            text-align: center;
            color: #002163;
            width: 300px;
            margin: 0 auto;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                display: block;
                font-size: 38px;
                line-height: 40px;
                width: 100%;
            }
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                line-height: 42px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 58px;
                line-height: 58px;
            }
        }

    }

    .wrapTest {
        padding: 20px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media-breakpoint-up(xl) {
            width: 1024px;
        }

        .slick-dots {
            li {
                button {
                    &:before {
                        font-size: 15px;
                    }
                }
            }
        }

        .slick-slide {

            @include media-breakpoint-up(md) {
                &:hover {
                    .itemTestimonial {
                        //box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);

                        label, p {
                            background-color: white;
                        }
                    }
                }
            }

            &.slick-active {
                .itemTestimonial {
                    //box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);

                    label, p {
                        background-color: white;
                    }
                }
            }

            .itemTestimonial {
                width: 96% !important;
                margin-bottom: 20px;

                img {
                    display: block;
                    margin: 50px auto 20px;
                }

                &.type1 {
                    p, label {
                        color: white;
                        background-color: #002163;
                    }
                }
                &.type2 {

                }
                &.type3 {
                    p, label {
                        color: white;
                        background-color: #084FA0;
                    }
                }
                label {
                    background-color: #F0F0F0;
                    color: #000;
                    padding-top: 15px;
                    margin-bottom: 0;
                    display: block;
                    text-align: center;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;

                    strong {
                        display: block;
                    }
                }

                p {
                    background-color: #F0F0F0;
                    color: #000;
                    display: block;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    margin: 0;
                    padding: 20px 20px 40px 20px;
                    min-height: 204px;
                }
            }

        }

    }

}

//.ichepTestimonials

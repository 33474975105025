.projects {

    .bannerBox {
        padding-top: 94px;

        .item {
            .container {
                .BannerCaption {
                    h1 {
                        font-size: 2.125em;

                        @include media-breakpoint-up(lg) {
                            font-size: 54px;
                        }

                        span {
                            @include media-breakpoint-up(lg) {
                                font-size: 24px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    #projectsTabs {
        background-color: $brand-secondary;
        width: 100%;
        margin: 0;
        padding: 0;

        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        li {
            list-style: none;
            position: relative;
            height: 63px;
            width: 33.33%;

            &.middle {
                &:before {
                    background-color: $brand-primary;
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 70%;
                    width: 1px;
                    transition: all 0.5s;
                }
                &:after {
                    background-color: $brand-primary;
                    content: '';
                    position: absolute;
                    right: -1px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 70%;
                    width: 1px;
                    transition: all 0.5s;
                }
            }

            &.current {
                &.middle {
                    &:before {
                        left: 1px;
                    }
                    &:after {
                        right: 1px;
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                width: 325px;
            }

            a {
                border: 0;
                border-radius: 0;
                color: #002163;
                height: 100%;
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                padding-top: 7px;
                justify-content: center;
                display: flex;
                align-items: center;
                width: 100%;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    line-height: 23px;
                    padding-top: 10px;
                }

                &#generalConstructorID {
                    padding-top: 15px;

                    @include media-breakpoint-up(md) {
                        padding-top: 20px;
                    }
                }
                &#preliminaryStudiesID {
                    padding-top: 15px;

                    @include media-breakpoint-up(md) {
                        padding-top: 20px;
                    }
                }

                &.active {
                    background-color: $brand-primary;
                    color: white;
                    font-weight: 600;
                }
            }
        }
    }
    //#projectsTabs

    .tab-content {
        padding-top: 65px;

        @include media-breakpoint-up(xl) {
            padding-top: 90px;
        }

        .block-title {
            margin: 0 auto 95px auto;
            text-align: center;
            width: 290px;

            @include media-breakpoint-up(md) {
                width: 600px;
            }
            @include media-breakpoint-up(xl) {
                width: 740px;
            }

            h2 {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 32px;
                margin-bottom: 10px;
                color: #002163;

                @include media-breakpoint-up(xl) {
                    font-size: 58px;
                    margin-bottom: 20px;
                }
            }
            p {
                font-family: $font-family-roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #123986;

                @include media-breakpoint-up(xl) {
                    font-size: 20px;
                    line-height: 28px;
                }

                strong {
                    font-weight: 600;
                }
            }
        }
        //.block-title

    }
    //.tab-content

    .tab-pane {
        position: relative;

        .item-project {
            overflow: hidden;
            margin: 15px;

            &:hover {

                img {
                    transform: scale(1.5);
                }
            }

            .project-img {
                background-blend-mode: multiply;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: relative;
            }
            img {
                transition: all 0.5s ease-out;
            }

            .wrap-project-name {
                position: absolute;
                left: 50%;
                top: 50%;
                height: calc(100% - 27px);
                width: calc(100% - 27px);
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: all 0.5s ease-out;

                label {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 26px; /* 108.333% */
                    height: 75px;
                    left: 10%;
                    bottom: 10%;
                    position: absolute;
                    width: 85%;

                    @include media-breakpoint-up(lg) {
                        width: 290px;
                    }

                }
            }

            &.active, &:hover {
                .project-img {
                    background-color: rgba(8, 79, 160, 0.8);
                }
                .wrap-project-name {
                    //background-color: rgba(8, 79, 160, 0.8);
                    opacity: 1;
                }
            }
        }
    }

    .wrap-client-testimonial {
        background-image: url("/images/projects/background-clients-testimonial.webp");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;

        .ichepOurClients, .ichepTestimonials {
            background-color: transparent;
        }
    }
}

.project-detail {

    .project-header {
        padding-top: 94px;
        margin-bottom: 80px;

        @include media-breakpoint-up(lg) {
            padding-top: 94px;
            margin-bottom: 160px;
        }

        .header {
            .col-left {
                position: relative;
            }
            .col-right {
                position: relative;

                .header-title {
                    background-color: $project-header;
                    color: white;
                    position: relative;
                    height: 500px;
                    min-height: 500px;
                    margin: 0 auto;
                    top: 0;
                    left: 0;
                    padding: 55px 0 55px 0;
                    width: 100%;

                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        height: 85%;
                        min-height: 500px;
                        margin: 0 auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 55px 55px 55px 0;
                        width: 100%;
                    }

                    .decoration {
                        display: none;

                        @include media-breakpoint-up(lg) {
                            background-color: $project-header;
                            color: white;
                            display: block;
                            position: absolute;
                            height: 100%;
                            min-height: 500px;
                            margin: 0 auto;
                            top: 50%;
                            left: 0;
                            transform: translate(-99px, -50%);
                            width: 100px;
                        }
                    }

                    h1 {
                        display: block;
                        font-family: $font-family-roboto;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 38px;
                        margin: 0 auto 43px;
                        width: 90%;
                        max-width: 385px;

                        @include media-breakpoint-up(lg) {
                            font-size: 35px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 46px;
                            margin-bottom: 43px;
                            width: 385px;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size: 51px;
                            width: 540px;
                            margin-left: 0;
                            margin-right: 0;
                            line-height: 60px;
                            max-width: 540px;
                        }
                        @media (min-width: 1600px){
                            font-size: 54px;
                            width: 580px;
                            line-height: 70px;
                            max-width: 580px;
                        }
                    }

                    .wrap-challenge {
                        position: relative;
                        padding-top: 37px;
                        margin: 0 auto 43px;
                        width: 90%;

                        @include media-breakpoint-up(lg) {
                            margin-left: 0;
                            margin-right: 0;
                            max-width: 500px;
                        }

                        .line {
                            background: #245EAE;
                            background-blend-mode: multiply;
                            height: 2px;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: -100px;
                        }

                        p {
                            color: #FFF;
                            font-family: $font-family-roboto;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 30px;
                            max-width: 400px;

                            b {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .col-features {
                display: flex;
                align-items: center;
                justify-content: end;

                .wrap-feature {
                    padding-left: 5%;
                    padding-right: 5%;
                    padding-bottom: 33px;
                    padding-top: 33px;

                    @include media-breakpoint-up(lg) {
                        max-width: 600px;
                        padding-left: 0;
                        padding-right: 55px;
                        padding-bottom: 33px;
                        padding-top: 33px;
                    }

                    label {
                        color: $project-label;
                        font-family: $font-family-roboto;;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                        display: block;

                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                            line-height: 30px;
                        }

                        b {
                            font-weight: 500;
                        }
                    }
                }

                .line {
                    background: #245EAE;
                    background-blend-mode: multiply;
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }
            }
        }
        //.header


    }
    //.project-header

    .project-body {
        background: linear-gradient(180deg, rgba(255,255,255,1) 18%, rgba(246,246,246,1) 18%);

        .body {
            text-align: center;
            padding-bottom: 60px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 120px;
            }

            img {
                margin: 0 auto;
                display: block;
                max-width: 100%;
                height: auto;
                width: 100%;
            }

            h2 {
                color: $brand-primary;
                font-family: $font-family-roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 85.714% */
                display: block;
                text-align: left;

                @include media-breakpoint-up(lg) {
                    font-size: 28px;
                }
            }

            p {
                display: block;
                text-align: left;
                color: #000;
                font-family: $font-family-roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                }

                b, strong {
                    font-weight: 500;
                }
            }

            ul {
                margin: 0;
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;

                li {
                    color: #000;
                    list-style: none;
                    display: block;
                    text-align: left;
                    font-weight: 300;
                    font-size: 14px;
                    position: relative;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }

                    &:before {
                        content: "•";
                        color: $brand-primary;
                        display: inline-block;
                        margin-left: -0.6em;
                        width: 1em;
                        font-size: 31px;
                        line-height: 0;
                        margin-top: 10px;
                        position: absolute;
                        left: 0;
                        top: 2px;
                    }
                }
            }
        }
        //.body

    }
    //.project-body

    .project-footer {
        .footer {
            margin-bottom: 30px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 50px;
            }
            .col-left {
                height: 500px;
                background-color: rgba(8, 79, 160, 1);
                background-blend-mode: multiply;
                background-size: 380% auto;
                background-position: center center;
                background-repeat: no-repeat;

                @include media-breakpoint-up(md) {
                    height: 550px;
                }
                @include media-breakpoint-up(xl) {
                    background-size: 280% auto;
                }

                p {
                    position: relative;
                    color: white;
                    font-family: $font-family-roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px;
                    height: 223px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 85%;

                    @include media-breakpoint-up(md) {
                        width: 80%;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 18px;
                        line-height: 24px;
                        width: 505px;
                    }

                    b, strong {
                        font-weight: 500;
                    }
                }
            }
            .col-right {
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                height: 500px;

                @include media-breakpoint-up(md) {
                    height: 550px;
                }
            }
        }
        //.footer

    }
    //.project-footer

    .more-projects {
        margin-bottom: 90px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 190px;
        }
    }

    .title {
        color: $project-label;
        font-family: $font-family-roboto;
        font-size: 38px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        margin-bottom: 25px;
        margin-top: 25px;

        @include media-breakpoint-up(md) {
            font-size: 58px;
            line-height: 60px;
            margin-bottom: 80px;
            margin-top: 80px;
        }
    }
}
